import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | Scone Tennis Courts
			</title>
			<meta name={"description"} content={"Elevate Your Game with Premier Services"} />
			<meta property={"og:title"} content={"About Us | Scone Tennis Courts"} />
			<meta property={"og:description"} content={"Elevate Your Game with Premier Services"} />
			<meta property={"og:image"} content={"https://groniblast.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://groniblast.com/img/54774375.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://groniblast.com/img/54774375.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://groniblast.com/img/54774375.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://groniblast.com/img/54774375.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://groniblast.com/img/54774375.png"} />
			<meta name={"msapplication-TileImage"} content={"https://groniblast.com/img/54774375.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://groniblast.com/img/4.jpg"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="40px 0px 0px 50px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="--primary" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
					Comprehensive Tennis Services
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
					About Us
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
					At Scone Tennis Courts, we go beyond providing excellent courts – we offer a comprehensive range of services designed to enhance your tennis experience. Whether you're looking for top-tier training, equipment rental, or event hosting, our facility is equipped to meet all your tennis needs.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						margin="30px 0px 0px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box min-width="100px" min-height="80px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Expert Coaching and Training
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Our team of professional coaches is dedicated to helping you improve your game. Whether you're a beginner or an advanced player, we offer training programs tailored to your skill level. Our coaches bring years of experience and a passion for the sport, ensuring you get the best guidance possible.
							</Text>
						</Box>
						<Box min-width="100px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px" min-height="80px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Premium Court Facilities
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							Our courts are maintained to the highest standards, providing an excellent playing surfScone year-round. We offer a range of court options to suit different preferences and playing styles.
							</Text>
						</Box>
						<Box min-width="100px" min-height="80px" padding="0px 5px 0 5px" margin="0px 0px 35px 0px">
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Hard Courts: Standard playing surfScone used in many tournaments.
								<br />
								<br />
								Clay Courts: Softer surfScone offering a different playing experience.
							</Text>
						</Box>

						<Box min-width="100px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px" min-height="80px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
							Equipment Rental and Pro Shop
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
							We provide top-quality tennis equipment to enhance your game. Whether you need to rent or purchase, our pro shop has everything you need.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});